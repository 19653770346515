import React, { useState } from "react"

import styled from "styled-components"
import PageWrapper from "../components/PageWrapper"

import { Title, Section, Text } from "../components/Core"
import { Container, Row, Col, Spinner } from "react-bootstrap"
import { t } from "@lingui/macro"
import SEO from "../components/SEO"

const HeroWrapper = styled(Section)`
  background-color: #f4f4f4;
`

const SpinnerWrapper = styled.div`
  background-color: #f4f4f4;
  padding-bottom: 80px;
`

const Appointment = ({ location }) => {
  var [loading, setLoading] = useState(true)
  function hideSpinner(e) {
    setLoading(false)
  }
  return (
    <>
      <SEO
        pathname={location.pathname}
        title={t`Termin Vereinbaren`}
        description={t`Vereinbaren Sie ein kostenfreien Beratungstermin für die Möglichkeiten von künstlicher Intelligenz. AMAI hilft Ihnen, Künstliche Intelligenz in Ihre Prozesse und Produkte zu integrieren. Auf dem Gebiet des Machine Learning, Deep Learning sind wir Experten.`}
      />
      <PageWrapper footerHide={!loading}>
        <HeroWrapper>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <div className="banner-content">
                  <Title variant="hero">{t`Direkt Termin vereinbaren`}</Title>
                  <Text>
                    {t`In folgendem Formular können Sie direkt einen kostenfreien Video-Telefontermin mit uns vereinbaren. Einfach freien Termin wählen und wir senden Ihnen eine Einladung.`}
                  </Text>
                </div>
              </Col>
            </Row>
          </Container>
        </HeroWrapper>
        {loading ? (
          <SpinnerWrapper>
            <div class="d-flex justify-content-center">
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </SpinnerWrapper>
        ) : null}
        <iframe
          title="Make Appointment"
          src="https://outlook.office365.com/owa/calendar/AMAIGmbH@am.ai/bookings/"
          width="100%"
          height={loading ? "0px" : "1500px"}
          scrolling="no"
          onLoad={hideSpinner}
          css={{ border: 0 }}
        ></iframe>
      </PageWrapper>
    </>
  )
}
export default Appointment
